import emailjs from "emailjs-com"
import React from 'react';

const Footer = () => {
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }
    return (
        <footer id="contact" className="footer">
            <div className="container footer-content text-center">
                <div id='success' className="success">Sent!<br /><p className='small'>Thank you for reaching out:)</p></div>
                <div id='contactForm' className="footer-bottom">
                    <div className="section-title">
                        <h2>Get In Touch</h2>
                    </div>
                    <form className="newsletter-form" onSubmit={sendEmail}>
                        <input required type="text" name='name' placeholder='Your Name' className="form-control black"></input>
                        <input type="email" name='email' required placeholder='Email Adress' className="form-control black"></input>
                        <input type="text" name='phone' required placeholder='Phone number' className="form-control black"></input>
                        <textarea required name='message' rows="6" placeholder="Message.." className='form-control textArea black'></textarea>
                        <button type="submit" className="btn submit-btn">Send</button>
                        <a target="_blank" href='https://calendly.com/williamgrube/meeting-with-william-gruvy-education' className="btn submit-btn">Schedule a Virtual Meeting</a>
                    </form>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="bottom-footer-container">
                    <div className="bottom-footer-section">
                        <h4>About Gruvy Education</h4>
                        <p>We deliver AI Training to schools and major educational associations nationwide.</p>
                    </div>
                    <div className="bottom-footer-section">
                        <h4>Contact</h4>
                        <a href="mailto:william.grube@gruvyeducation.com">Email: william.grube@gruvyeducation.com</a>
                        <a href="tel:507-476-8096">Phone: 507-476-8096</a>
                    </div>
                    <div className="bottom-footer-section">
                        <h4>Follow Us</h4>
                        <a target="_blank" href="https://twitter.com/Gruvy_edu">Twitter</a>
                        <a target="_blank" href="https://www.linkedin.com/in/william-grube-16200a177/">LinkedIn</a>
                        <a target="_blank" href="https://www.facebook.com/profile.php?id=100092009478609">Facebook</a>
                    </div>
                </div>
                <div className="bottom-footer-bottom">
                    <p>&copy; {new Date().getFullYear()} Gruvy Education | All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
