import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './MoASSPFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavMoASSP from './NavMoASSP';
import Guide from '../../images/Guide.png';
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const MoASSP = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <body>
            {showPopup && (
                <div className="popup-overlay" onClick={() => setShowPopup(false)}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-button" onClick={() => setShowPopup(false)}>
                            &times;
                        </button>
                        <div className="form-container">
                            {successMessage ? (
                                <div className="success-message">{successMessage}</div>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <h2 className="form-title">Get AI Keynote Resources via Email</h2>
                                    <p className="form-description">
                                        Enter your email, and I will send the resources right to your inbox.
                                    </p>
                                    <input
                                        type="email"
                                        id="email"
                                        name="user_email"
                                        placeholder="Email address"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <button type="submit" className="submit-button" disabled={isSubmitting}>
                                        {isSubmitting ? 'Sending...' : 'Get Resources'}
                                    </button>
                                </form>
                            )}
                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </div>
                    </div>
                </div>
            )}
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavMoASSP module="home" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">AI Keynote Resources</div>
                        </div>
                    </header>
                    <div className="course-container">
                        <div className="MoASSP-course-navbar">
                            <ScrollLink
                                activeClass="active"
                                to="resources"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Resources">📦💡</span> Resources
                                </div>
                            </ScrollLink>
                            <div
                                onClick={() => setShowPopup(true)}
                                className="MoASSP-course-nav-item MoASSP-cursor-pointer"
                            >
                                <div>
                                    <span role="img" aria-label="Email">✉️📥</span> Get This Webpage Sent to Your Email
                                </div>
                            </div>
                            <ScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">📞🎤</span> Contact Speaker
                                </div>
                            </ScrollLink>
                        </div>
                        <section name='resources' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">📦💡Resources</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://drive.google.com/file/d/1sMVqVShJOi539RIix7d68dW2J9cQJY6V/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">ChatGPT Guide for School Leaders: ChatGPT Uses & Prompts</h3>
                                    <p className="MoASSP-tool-text">30+ ways to use ChatGPT as a School Leader.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={PromptDesign} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Framework for Designing ChatGPT Prompts</h3>
                                    <p className="MoASSP-tool-text">A guide on how school leaders should design prompts for ChatGPT.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gruvy Education's Guide to Data Privacy With AI</h3>
                                    <p className="MoASSP-tool-text">A school's guide to student data privacy with AI.</p>
                                </a>
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Educator ChatGPT Prompt Library</h3>
                                    <p className="MoASSP-tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide2} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Guide for Navigating AI Tools as an Educator</h3>
                                    <p className="MoASSP-tool-text">A guide outlining popular educator-specific AI tools, what they do, and whether they are free or their cost.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1M2Dejn-OC27POEfaHSPsgVqSfc_eeCb6/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIresistant} alt="Magic School" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Creating AI-Resistant Curriculum: A Guide for Educators</h3>
                                    <p className="MoASSP-tool-text">A guide to keeping students learning in the AI era by crafting AI-resistant curriculum.</p>
                                </a>
                                <a href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Newsletter} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Direct AI Updates for School Leaders</h3>
                                    <p className="MoASSP-tool-text">My "cut the fluff, only give me what I need" AI newsletter for school leaders.</p>
                                </a>
                                <ScrollLink activeClass="active" to="rupak-section" spy={true} smooth={true} offset={-70} duration={50} className="MoASSP-tool-card cursorPointer">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guidance} alt="SchoolAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Guidance and Responsible Use Resources</h3>
                                    <p className="MoASSP-tool-text">Resources for AI policy, responsible AI use by students, and more.</p>
                                </ScrollLink>
                                <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="MoASSP-tool-card cursorPointer">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Tool} alt="AI Tools" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Tools Highlighted in This Keynote</h3>
                                    <p className="MoASSP-tool-text">A list of all AI tools mentioned in the keynote.</p>
                                </ScrollLink>
                                <a href="https://www.moassp.com/events-list/#!event/2024/10/3/maesp-moassp-join-to-presentai-mult-part-leadership-series" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={MoASSPLogo} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">MAESP & MoASSP Join to present AI: Mult-Part Leadership Series</h3>
                                    <p className="MoASSP-tool-text">Register now for sessions on October 3rd, 10th, and 17th, from 9:00 AM to 10:00 AM.</p>
                                </a>
                                <Link to="/aboutgruvyeducation" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Logo} alt="Gruvy Education Training" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Learn More About Gruvy Education's AI Training</h3>
                                    <p className="MoASSP-tool-text">Click here to learn more about the training 100+ schools and state-level organizations have adopted.</p>
                                </Link>
                            </div>
                        </section>
                        <section name='rupak-section' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">🤖📚 AI Guidance</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://www.teachai.org/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={TeachAIpolicy} alt="TeachAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">TeachAI | AI Guidance for Schools</h3>
                                    <p className="MoASSP-tool-text">TeachAI provides free AI policy resources and guidance for schools.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1px7ZjoCuOA-CpwdQl2pC_QOhiXem-o3M/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={TeacherChecklist} alt="AI Guidelines for Students" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Classroom Use: Guidelines for Students</h3>
                                    <p className="MoASSP-tool-text">An easy-to-understand graphic for students on using AI responsibly.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1Chyf1e2BrPwGHod7HNFucM6agBKHQVzq/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={StudentGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Teacher’s Checklist for Using AI with Students in the Classroom</h3>
                                    <p className="MoASSP-tool-text">A practical checklist for teachers to effectively implement AI tools in the classroom.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gruvy Education's Guide to Data Privacy With AI</h3>
                                    <p className="MoASSP-tool-text">A school's guide to student data privacy with AI.</p>
                                </a>
                                <a href="https://www.aiforeducation.io/ai-resources-main" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIEDU} alt="AIEDU" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI for Education | Downloadable Resources</h3>
                                    <p className="MoASSP-tool-text">Free AI resources for your school.</p>
                                </a>
                            </div>
                        </section>
                        <section name='breakout' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">🔧💻 AI Tools Highlighted in Keynote</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide2} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Guide for Navigating AI Tools as an Educator</h3>
                                    <p className="MoASSP-tool-text">A guide outlining popular educator-specific AI tools, what they do, and whether they are free or their cost.</p>
                                </a>

                                <a href="https://chat.openai.com/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={ChatGPT} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">ChatGPT</h3>
                                    <p className="MoASSP-tool-text">ChatGPT direct link.</p>
                                </a>

                                <a href="https://app.magicschool.ai/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Magic School</h3>
                                    <p className="MoASSP-tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>

                                <a href="https://schoolai.com/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={SchoolAI} alt="SchoolAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">SchoolAI</h3>
                                    <p className="MoASSP-tool-text">Designed to safely integrate AI into the classroom for K-12 students, with built-in monitoring and controls for teachers.</p>
                                </a>

                                <a href="https://web.diffit.me/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Diffit} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Diffit</h3>
                                    <p className="MoASSP-tool-text">Diffit helps teachers save time by providing "just right" instructional materials, ensuring all students access grade-level content.</p>
                                </a>
                                <a href="https://www.khanacademy.org/teacher/khanmigo-tools" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Khan} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Khanmigo Tools</h3>
                                    <p className="MoASSP-tool-text">Free AI powered tools designed to save you time and improve instruction!</p>
                                </a>
                                <a href="https://gamma.app/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Gamma} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gamma</h3>
                                    <p className="MoASSP-tool-text">Your AI-powered presentation creator.</p>
                                </a>
                                <a href="https://www.eduaide.ai/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Eduaide} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Eduaide.Ai</h3>
                                    <p className="MoASSP-tool-text">Eduaide.AI is your AI-powered tool for designing lessons, creating resources, and offering feedback, guided by your expertise.</p>
                                </a>
                                <a href="https://chromewebstore.google.com/detail/brisk-teaching/pcblbflgdkdfdjpjifeppkljdnaekohj" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Brisk} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Brisk Teaching</h3>
                                    <p className="MoASSP-tool-text">A Chrome extension designed to save teachers time with AI-powered tools.</p>
                                </a>
                                <a href="https://curipod.com/21ec955a-72e5-4169-96c1-0352e9f11cbd/home" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Curipod} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Curipod</h3>
                                    <p className="MoASSP-tool-text">Instantly create lessons, slides, exit tickets, polls, and questions with AI-generated content.</p>
                                </a>
                            </div>
                        </section>
                        <section name='NextSteps' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">✅ Next Steps</h2>
                            <div className="MoASSP-tool-grid">
                                <Link to="/aboutgruvyeducation" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Logo} alt="Gruvy Education Training" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Learn More About Gruvy Education's AI Training</h3>
                                    <p className="MoASSP-tool-text">Click here to learn more about the training 100+ schools and state-level organizations have adopted.</p>
                                </Link>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Form} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Apply to Bring Gruvy Education's AI Training to Your Educators</h3>
                                    <p className="MoASSP-tool-text">Apply to join the 100+ schools participating in our AI in Education Training.</p>
                                </a>
                                <a href="https://calendly.com/williamgrube/meeting-with-william-gruvy-education" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Schedule a Meeting with William</h3>
                                    <p className="MoASSP-tool-text">We will talk where your staff is at with AI, what the training involves, and it it is a good fit.</p>
                                </a>


                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default MoASSP;
